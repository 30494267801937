import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { HealthInsuranceComparisonModel } from '../models/health-insurance-comparison.model';
import { HealthInsuranceComparisonResource } from '../models/health-insurance-comparison.resource';


@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class HealthInsuranceComparisonMapper extends ResourceMapper<HealthInsuranceComparisonModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigHealthInsuranceComparison;

    public constructor() {
        super();
    }

    public map(resource: HealthInsuranceComparisonResource): HealthInsuranceComparisonModel {
        const companiesArray = resource.attributes.health_insurance_companies?.split('\r\n');

        if (!companiesArray?.length) {
            return {
                type: ParagraphTypes.HealthInsuranceComparison,
            };
        }

        // eslint-disable-next-line sonarjs/slow-regex
        const regex = /(.+?[^\s])\|(.+?)$/;
        const nameIndex = 1;
        const companyIdIndex = 2;
        const companies: Record<string, string> = {};

        companiesArray.forEach(companyStr => {
            const match = regex.exec(companyStr);

            if (!match) {
                return;
            }

            companies[match[nameIndex]] = match[companyIdIndex];
        });

        return {
            type: ParagraphTypes.HealthInsuranceComparison,
            companies,
        };
    }
}
