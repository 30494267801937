import { Injectable, inject } from '@angular/core';
import { JsonApiResourceMapper, ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { DPPGroupedLinkListsCollectorModel } from '../models/dpp-grouped-link-lists-collector.model';
import { DPPGroupedLinkListsCollectorResource } from '../models/dpp-grouped-link-lists-collector.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class DPPGroupedLinkListsCollectorMapper extends ResourceMapper<DPPGroupedLinkListsCollectorModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphDPPGroupedLinkListsCollector;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: DPPGroupedLinkListsCollectorResource, collection: ResourceCollection): DPPGroupedLinkListsCollectorModel {
        return {
            type: ParagraphTypes.GroupedLinkListsCollector,
            groupedLinkLists: this.resourceIdentifierMapper.mapMultiple(resource.relationships.grouped_link_lists.data ?? undefined, collection) ?? [],
        };
    }
}
