import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type KeyAccountManagerModel } from './key-account-manager.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class KeyAccountManagerMapper extends ResourceMapper<KeyAccountManagerModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphDPPKeyAccountManager;

    public map(): KeyAccountManagerModel {
        return {
            type: ParagraphTypes.KeyAccountManager,
        };
    }
}
