import { Injectable, inject } from '@angular/core';
import {
    BadgesResource,
    JsonApiResourceMapper,
    ResourceCollection,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type BadgesModel } from './badges.model';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class BadgesMapper extends ResourceMapper<BadgesModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigBadges;

    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: BadgesResource, collection: ResourceCollection): BadgesModel {
        return {
            type: ParagraphTypes.Badges,
            badges: this.resourceIdentifierMapper.mapMultiple(resource.relationships.badge.data ?? undefined, collection) ?? [],
            title: resource.attributes.title ?? undefined,
        };
    }
}
