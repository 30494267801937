import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { KeyAccountManagerMapper } from './key-account-manager.mapper';

export const provideKeyAccountManagerParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[KeyAccountManagerMapper.type], useClass: KeyAccountManagerMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./key-account-manager.component'),
                type: ParagraphTypes.KeyAccountManager,
            },
            multi: true,
        },
    ]);
