import { Injectable, inject } from '@angular/core';
import { JsonApiResourceMapper, ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { type KeyAccountManagerSearchModel } from './key-account-manager-search.model';
import { KeyAccountManagerSearchResource } from './key-account-manager-search.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class KeyAccountManagerSearchMapper extends ResourceMapper<KeyAccountManagerSearchModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphDPPKeyAccountManagerSearch;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: KeyAccountManagerSearchResource, collection: ResourceCollection): KeyAccountManagerSearchModel {
        return {
            type: ParagraphTypes.KeyAccountManagerSearch,
            paragraphs: this.resourceIdentifierMapper.mapMultiple(resource.relationships.paragraphs.data, collection) ?? [],
        };
    }
}
