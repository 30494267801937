import { Injectable, inject } from '@angular/core';
import {
    JsonApiResourceMapper,
    MediaFileIdentifier,
    ResourceCollection,
    ResourceIdentifierMapperService,
    ResourceMapper,
    ResourceTypes,
} from '@big-direkt/json-api-client';
import { LinkListItem, ParagraphTypes } from '@big-direkt/utils/shared';
import { DownloadListModel } from '../models/download-list.model';
import { DownloadListResource } from '../models/download-list.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class DownloadListMapper extends ResourceMapper<DownloadListModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigDownloadList;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: DownloadListResource, collection: ResourceCollection): DownloadListModel {
        return {
            type: ParagraphTypes.DownloadList,
            items: this.mapToLinkItems(resource, collection),
            heading: resource.attributes.heading ?? undefined,
        };
    }

    private mapToLinkItems(resource: DownloadListResource, collection: ResourceCollection): LinkListItem[] {
        const mediaData = resource.relationships.grouped_link_lists_file.data ?? undefined;
        const paragraphs = this.resourceIdentifierMapper.mapMultiple<MediaFileIdentifier>(mediaData, collection) ?? [];

        if (!paragraphs.length) {
            return [];
        }

        const files: LinkListItem[] = [];
        paragraphs.forEach(item => {
            files.push({
                type: ParagraphTypes.LinkListItem,
                href: item.meta.uri,
                title: item.meta.title,
                isDownloadLink: true,
                pdfFileKey: item.pdfFile?.name,
                fileName: `${item.meta.title}.${item.meta.type}`,
            })
        });

        return files;
    }
}
