import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeDPPPrivateAreaMapper } from '../mappers/node-dpp-private-area.mapper';

export const provideNodeDPPPrivateArea = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeDPPPrivateAreaMapper.type],
            useClass: NodeDPPPrivateAreaMapper,
        },
    ]);
