import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { SalesPerformanceModel } from '../models/sales-performance.model';
import { SalesPerformanceResource } from '../models/sales-performance.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class SalesPerformanceMapper extends ResourceMapper<SalesPerformanceModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphSalesPerformance;

    public map(resource: SalesPerformanceResource): SalesPerformanceModel {
        return {
            type: ParagraphTypes.SalesPerformance,
            salesCommission: Number.parseFloat(resource.attributes.sales_commission ?? '0'),
        };
    }
}
