import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { HealthInsuranceComparisonMapper } from '../mapper/health-insurance-comparison.mapper';

export const provideHealthInsuranceComparisonParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[HealthInsuranceComparisonMapper.type], useClass: HealthInsuranceComparisonMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./../components/health-insurance-comparison.component'),
                type: ParagraphTypes.HealthInsuranceComparison,
            },
            multi: true,
        },
    ]);
