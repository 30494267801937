import { Injectable, inject } from '@angular/core';
import { JsonApiResourceMapper, ResourceCollection, ResourceIdentifierMapperService, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { GroupedLinkListsModel } from '../models/grouped-link-lists.model';
import { GroupedLinkListsResource } from '../models/grouped-link-lists.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class GroupedLinkListsMapper extends ResourceMapper<GroupedLinkListsModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphBigGroupedLinkLists;
    private readonly resourceIdentifierMapper = inject(ResourceIdentifierMapperService);

    public map(resource: GroupedLinkListsResource, collection: ResourceCollection): GroupedLinkListsModel {
        return {
            type: ParagraphTypes.GroupedLinkLists,
            id: resource.id,
            downloadList: this.resourceIdentifierMapper.mapMultiple(resource.relationships.download_list.data ?? undefined, collection) ?? [],
            heading: resource.attributes.heading,
            content: resource.attributes.text_long_formatted?.processed,
        };
    }
}
