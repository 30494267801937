import { Location, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, OnInit, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TabNavComponent, TabNavLink, TabNavService } from '@big-direkt/a11y';
import { JsonApiClientService, ResourceTypes } from '@big-direkt/json-api-client';
import { NavHeaderComponent, NavSidenavComponent } from '@big-direkt/nav';
import { UiRepository } from '@big-direkt/state/ui';
import { TrackingModule } from '@big-direkt/tracking';
import { UiLinkModule } from '@big-direkt/ui/link';
import { NavigationExtrasState } from '@big-direkt/ui/pagination';
import { UiTopBarComponent } from '@big-direkt/ui/top-bar';
import { CookiebotService, ScrollService, WindowService } from '@big-direkt/utils/environment';
import { BreakpointService, PlatformService, ResizedDirective, ResizedEvent, TopBarModel } from '@big-direkt/utils/shared';
import { TranslocoPipe } from '@jsverse/transloco';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { map, tap } from 'rxjs';
import { FooterComponent } from './footer/footer.component';

@Component({
    selector: 'big-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    imports: [
        NgStyle,
        FooterComponent,
        LoadingBarRouterModule,
        RouterOutlet,
        TabNavComponent,
        TrackingModule,
        TranslocoPipe,
        UiTopBarComponent,
        UiLinkModule,
        ResizedDirective,
        NavSidenavComponent,
        NavHeaderComponent,
    ],
})
export class AppComponent implements OnInit {
    private readonly uiRepository = inject(UiRepository);
    private readonly jsonApiClientService = inject(JsonApiClientService);
    private readonly injector = inject(Injector);
    private readonly breakpointService = inject(BreakpointService);
    private readonly a11yNavService = inject(TabNavService);
    private readonly router = inject(Router);
    private readonly cookiebotService = inject(CookiebotService);
    private readonly platformService = inject(PlatformService);
    private readonly scrollService = inject(ScrollService);
    private readonly windowService = inject(WindowService);
    private readonly location = inject(Location);

    public readonly isFormShown = this.uiRepository.isFormShown;
    public readonly topBarSize = this.breakpointService.topBarSize;

    public readonly footer: TabNavLink = {
        title: 'a11yTabNav.footerLink.title',
        key: 'footer',
        displayValue: 'a11yTabNav.footerLink.displayValue',
        viewPort: 'all',
        order: 50,
    };
    public readonly mainContent: TabNavLink = {
        title: 'a11yTabNav.contentLink.title',
        key: 'content',
        displayValue: 'a11yTabNav.contentLink.displayValue',
        viewPort: 'all',
        order: 30,
    };

    public readonly topBars = toSignal<TopBarModel[]>(
        this.jsonApiClientService.find<TopBarModel>(ResourceTypes.NodeBigTopBar).pipe(
            tap(models => {
                this.uiRepository.setIsTopBarShown(models.length > 0);
            }),
            map(items =>
                items.map(item => ({
                    ...item,
                    backgroundColor: 'bg-primary-400',
                })),
            ),
        ),
        {
            injector: this.injector,
        },
    );

    private navigationExtras: NavigationExtrasState | undefined;

    public ngOnInit(): void {
        this.a11yNavService.registerLinks(this.mainContent, this.footer);
        this.cookiebotService.init();

        const routerEvents = toSignal(this.router.events, { injector: this.injector });

        effect(
            () => {
                const routerEvent = routerEvents();

                if (routerEvent instanceof NavigationEnd) {
                    this.navigationExtras = this.location.getState() as NavigationExtrasState | undefined;

                    if (this.navigationExtras?.doNotScroll ?? this.platformService.isPlatformServer()) {
                        return;
                    }

                    // Scroll on parameter change (like pagination page increase)
                    if (this.navigationExtras?.paginationChanged) {
                        this.scrollService.scroll(undefined, { focusElement: true });

                        return;
                    }

                    // Scroll on page change
                    this.scrollService.scrollTop('instant');

                    const topNav: HTMLDivElement | null = this.windowService.document().querySelector('#top');

                    if (!topNav) {
                        this.navigationExtras = undefined;

                        return;
                    }

                    topNav.focus();
                    this.navigationExtras = undefined;
                }
            },
            { injector: this.injector },
        );
    }

    public onTopBarResized(event: ResizedEvent): void {
        this.breakpointService.topBarSize.set({
            height: event.newRect.height,
            width: event.newRect.width,
        });
    }

    public onHeaderResized(event: ResizedEvent): void {
        this.breakpointService.headerSize.set({
            height: event.newRect.height,
            width: event.newRect.width,
        });
    }
}
