import { Injectable } from '@angular/core';
import { ResourceMapper, ResourceTypes, RestApiResourceMapper } from '@big-direkt/rest-api-client';
import { AccountStatementsModel } from '../models/account-statements.model';
import { AccountStatementsResource } from '../models/account-statements.resource';

@Injectable({
    providedIn: 'root',
})
@RestApiResourceMapper
export class AccountStatementsMapper extends ResourceMapper {
    public static override readonly type: ResourceTypes = ResourceTypes.AccountStatements;

    public map(resource: AccountStatementsResource): AccountStatementsModel {
        return {
            total: resource.attributes.data.total,
            offset: resource.attributes.data.offset,
            limit: resource.attributes.data.limit,
            transactionsAccepted: resource.attributes.data.transactions_accepted,
            transactionsPending: resource.attributes.data.transactions_pending,
            transactionsReclaimed: resource.attributes.data.transactions_reclaimed,
            commission: resource.attributes.data.commission,
            reclaim: resource.attributes.data.reclaim,
            items: resource.attributes.data.items.map(x => ({
                lastName: x.last_name ?? '',
                firstName: x.first_name ?? '',
                applicationDate: x.application_date ?? '',
                memberSince: x.member_since ?? undefined,
                status: x.status?.id,
                commission: x.commission ?? undefined,
                payoffDate: x.payoff_date ?? undefined,
            })),
        };
    }
}
