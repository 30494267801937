import { bootstrapApplication } from '@angular/platform-browser';
import {
    browserTracingIntegration,
    init,
    makeBrowserOfflineTransport,
    makeFetchTransport,
    reportingObserverIntegration,
    type BrowserOptions,
} from '@sentry/angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.sentryDsn !== '' && environment.sentryEnvironment !== '') {
    const integrations: BrowserOptions['integrations'] = [reportingObserverIntegration()];

    if (Array.isArray(environment.sentryTracingOrigins) && environment.sentryTracingOrigins.length > 0) {
        integrations.push(browserTracingIntegration());
    }

    init({
        denyUrls: [
            /https:\/\/survey\.lamapoll\.de\/lp\/js\/lp\.ext\.loader\.js/,
        ],
        dsn: environment.sentryDsn,
        environment: environment.sentryEnvironment,
        transport: makeBrowserOfflineTransport(makeFetchTransport),
        integrations,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment.sentryTracesSampleRate,
        tracePropagationTargets: environment.sentryTracingOrigins,
    });
}

bootstrapApplication(AppComponent, appConfig).catch(err => {
    // eslint-disable-next-line no-console
    console.error(err);
});
