import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeDPPStartPageMapper } from '../mappers/node-dpp-start-page.mapper';

export const provideNodeDPPStartPage = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeDPPStartPageMapper.type],
            useClass: NodeDPPStartPageMapper,
        },
    ]);
