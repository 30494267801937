import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService as MapperInjectorServiceJson } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { MapperInjectorService as MapperInjectorServiceRest, RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { AccountStatementsMapper } from './mappers/account-statements.mapper';
import { SalesPerformanceMapper } from './mappers/sales-performance-paragraph.mapper';

export const provideSalesPerformanceParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.AccountStatements]: '/users/distributionpartner/account-statements' }, multi: true },
        { provide: MapperInjectorServiceRest.tokens[AccountStatementsMapper.type], useClass: AccountStatementsMapper },
        { provide: MapperInjectorServiceJson.tokens[SalesPerformanceMapper.type], useClass: SalesPerformanceMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./components/sales-performance.component'),
                type: ParagraphTypes.SalesPerformance,
            },
            multi: true,
        },
    ]);
