import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { NodeDPPArticleMapper } from '../mappers/node-dpp-article.mapper';

export const provideNodeDPPArticle = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: MapperInjectorService.tokens[NodeDPPArticleMapper.type],
            useClass: NodeDPPArticleMapper,
        },
    ]);
