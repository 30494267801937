import { Injectable } from '@angular/core';
import { JsonApiResourceMapper, ResourceMapper, ResourceTypes } from '@big-direkt/json-api-client';
import { ParagraphModel, ParagraphTypes } from '@big-direkt/utils/shared';
import { SalesPerformanceDashboardModel } from '../models/sales-performance-dashboard.model';
import { SalesPerformanceDashboardResource } from '../models/sales-performance-dashboard.resource';

@Injectable({
    providedIn: 'root',
})
@JsonApiResourceMapper
export class SalesPerformanceDashboardMapper extends ResourceMapper<ParagraphModel> {
    public static override readonly type: ResourceTypes = ResourceTypes.ParagraphSalesPerformanceDashboard;

    public map(resource: SalesPerformanceDashboardResource): SalesPerformanceDashboardModel {
        return {
            type: ParagraphTypes.SalesPerformanceDashboard,
            salesCommission: Number.parseFloat(resource.attributes.sales_commission ?? '0'),
            link: !resource.attributes.link ? undefined : {
                href: resource.attributes.link.url,
                title: resource.attributes.link.title,
            },
        };
    }
}
