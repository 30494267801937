import { type StaticEnvironmentModel } from '@big-direkt/utils/environment';
import { jsonLd } from './json-ld';
import { metaTags } from './metatags';

export const environment: StaticEnvironmentModel = {
    faqApiPath: 'jsonapi/node/faq_wrapper',
    formApiPath: 'api/form',
    jsonapiPath: '/jsonapi',
    jsonLd,
    metaTags,
    privateContentPath: '/de/meinbereich',
    production: true,
    publicFormsHostUrl: '/public-forms',
    restapiPath: '/api',
    sentryDsn: 'https://af37f440e4aa4f1ca49cebda418c4a6e@sentry.operations.big-osp.de/30',
    sentryEnvironment: 'big-dpp-prod',
    sentryRelease: 'big-dpp-prod',
    sentryTracesSampleRate: 0.1,
    sentryTracingOrigins: ['vertriebspartner.big-direkt.de', 'vertriebspartner.stage.big-direkt.de', 'vertriebspartner.test.big-direkt.de'],
    serviceToolsPath: '',
    sessionTokenApiPath: 'session/token',
    errorNodeIds: {
        notFound: {
            de: '38a41731-b53b-4885-b69c-9c9becd4a16d',
        },
    },
    servicePhoneNumber: {
        number: '+498004002007',
        title: '0800 400 2007',
    },
    errorPhoneNumber: {
        number: '+498004002007',
        title: '0800 400 2007',
    },
};
