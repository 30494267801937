import { type Route } from '@angular/router';
import { ContentResolverService } from '@big-direkt/content';

export const appRoutes: Route[] = [
    {
        path: 'de/meinbereich',
        loadChildren: async () => (await import('./partner-area/partner-area.routes')).partnerAreaRoutes,
        resolve: { content: ContentResolverService },
        runGuardsAndResolvers: 'always',
    },
    {
        path: '**',
        loadComponent: async () => (await import('./content/content.component')).ContentComponent,
        resolve: { content: ContentResolverService },
        data: { showPageTitle: true },
    },
];
