import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, importProvidersFrom, inject, provideAppInitializer, type ApplicationConfig } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withEnabledBlockingInitialNavigation, withPreloading } from '@angular/router';
import { provideNodeBigError } from '@big-direkt/error-handling';
import {
    provideFormViewAddress,
    provideFormViewAlert,
    provideFormViewCheckboxes,
    provideFormViewCheckbox,
    provideFormViewComposite,
    provideFormViewContactFormSearch,
    provideFormViewContainer,
    provideFormViewCurrencyInput,
    provideFormViewDateList,
    provideFormViewDate,
    provideFormViewDateRange,
    provideFormViewDateTime,
    provideFormViewElementsHolder,
    provideFormViewEmbeddedForm,
    provideFormViewFieldGroup,
    provideFormViewFieldSet,
    provideFormViewFileUpload,
    provideFormViewForm,
    provideFormViewFormRelation,
    provideFormViewHidden,
    provideFormViewHin,
    provideFormViewIFrame,
    provideFormViewItemCollection,
    provideFormViewItem,
    provideFormViewLabel,
    provideFormViewMarkup,
    provideFormViewMultipleItem,
    provideFormViewMultiple,
    provideFormViewNavigationButton,
    provideFormViewNumberField,
    provideFormViewOneTimeToken,
    provideFormViewPage,
    provideFormViewPasswordConfirm,
    provideFormViewPassword,
    provideFormViewPdfDownload,
    provideFormViewPreview,
    provideFormViewPrivacyPolicy,
    provideFormViewProgressBar,
    provideFormViewQuantitySelect,
    provideFormViewRadio,
    provideFormViewRadios,
    provideFormViewSearchableSelect,
    provideFormViewSelect,
    provideFormViewTextarea,
    provideFormViewTextfield,
    provideFormViewTime,
    provideFormViewTimeRange,
} from '@big-direkt/form/view';
import { JsonApiClientModule } from '@big-direkt/json-api-client';
import { NavService } from '@big-direkt/nav';
import {
    provideNodeBigAccordion,
    provideNodeBigAccordionItem,
    provideNodeBigArticle,
    provideNodeBigDashboard,
    provideNodeBigForm,
    provideNodeBigLegal,
    provideNodeBigMyBig,
    provideNodeBigNews,
    provideNodeBigTopBar,
    provideNodeDPPArticle,
    provideNodeDPPPrivateArea,
    provideNodeDPPStartPage,
    provideNodeQuickLink,
} from '@big-direkt/nodes';
import { provideAccordionParagraph } from '@big-direkt/paragraphs/accordion';
import { provideBadgesParagraph } from '@big-direkt/paragraphs/badges';
import { provideButtonParagraph } from '@big-direkt/paragraphs/button';
import { provideC2aBannerParagraph } from '@big-direkt/paragraphs/c2a-banner';
import { provideCardGroupParagraph } from '@big-direkt/paragraphs/card-group';
import { provideCookiebotParagraph } from '@big-direkt/paragraphs/cookiebot';
import { provideDPPGroupedLinkListsCollectorParagraph } from '@big-direkt/paragraphs/dpp-grouped-link-lists-collector';
import { provideGapRemoverParagraph } from '@big-direkt/paragraphs/gap-remover';
import { provideHeadingParagraph } from '@big-direkt/paragraphs/heading';
import { provideHealthInsuranceComparisonParagraph } from '@big-direkt/paragraphs/health-insurance-comparison';
import { provideIframeParagraph } from '@big-direkt/paragraphs/iframe';
import { provideImageParagraph } from '@big-direkt/paragraphs/image';
import { provideImageCreditsParagraph } from '@big-direkt/paragraphs/image-credits';
import { provideInstructionParagraph } from '@big-direkt/paragraphs/instruction';
import { provideKeyAccountManagerParagraph } from '@big-direkt/paragraphs/key-account-manager';
import { provideKeyAccountManagerSearchParagraph } from '@big-direkt/paragraphs/key-account-manager-search';
import { provideLinkParagraph } from '@big-direkt/paragraphs/link';
import { provideLinkListParagraph } from '@big-direkt/paragraphs/link-list';
import { provideListParagraph } from '@big-direkt/paragraphs/list';
import { provideNewsTeaserParagraph } from '@big-direkt/paragraphs/news-teaser';
import { provideQuickLinkListParagraph } from '@big-direkt/paragraphs/quick-link-list';
import { provideSalesPerformanceDashboardParagraph, provideSalesPerformanceParagraph } from '@big-direkt/paragraphs/sales-performance';
import { provideServiceToolParagraph } from '@big-direkt/paragraphs/service-tool';
import { provideTextParagraph } from '@big-direkt/paragraphs/text';
import { provideTextButtonParagraph } from '@big-direkt/paragraphs/text-button';
import { provideUserDataParagraph } from '@big-direkt/paragraphs/user-data';
import { provideUserDetailsParagraph } from '@big-direkt/paragraphs/user-details';
import { provideWebformParagraph } from '@big-direkt/paragraphs/webform';
import { RestApiClientModule } from '@big-direkt/rest-api-client';
import { provideAdvantageCalculatorServiceTool } from '@big-direkt/service-tools/advantage-calculator';
import { uiStore } from '@big-direkt/state/ui';
import { UserModule, provideDppUserProfile, userStore } from '@big-direkt/state/user';
import {
    CustomErrorHandlerService,
    DarkModeService,
    EnvironmentService,
    FeatureFlagsService,
    LogSentryInterceptor,
    STATIC_ENVIRONMENT,
    darkmodeStore,
    featureFlagsStore,
    responseInterceptor,
} from '@big-direkt/utils/environment';
import { provideTranslocoRoot } from '@big-direkt/utils/i18n';
import { TranslocoService } from '@jsverse/transloco';
import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { NgOverlayContainerModule } from 'ng-overlay-container';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

registerLocaleData(localeDE);

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        // We need to pre fetch our translations in order to use the pipe.
        // loading de uses the default assets/de.json file, only those translations are currently pre fetched
        // See ticket BIGDEV-3434 for context information why we had to do this
        provideAppInitializer(async () => {
            const initializerFn = (
                (translocoService: TranslocoService): (() => Promise<void>) =>
                    /* eslint-disable @stylistic/ts/indent */
                    async () =>
                        firstValueFrom(translocoService.load('de')).then()
            )(inject(TranslocoService));

            return initializerFn();
        }),
        provideAppInitializer(async () => {
            const initializerFn = (
                (
                    environmentService: EnvironmentService,
                    featureFlagService: FeatureFlagsService,
                    navService: NavService,
                    darkmodeService: DarkModeService,
                ): (() => Promise<void>) =>
                    /* eslint-disable @stylistic/ts/indent */
                    async () =>
                        firstValueFrom(environmentService.load()).then(async () => {
                            await Promise.all([
                                firstValueFrom(featureFlagService.load()),
                                navService.initHeaderMenus([
                                    { name: 'top', type: 'big-header-top' },
                                    { name: 'tools', type: 'big-header-tools' },
                                    { name: 'main', type: 'big-header-main' },
                                    { name: 'mainLoggedIn', type: 'big-header-main-logged-in' },
                                    { name: 'user', type: 'my-big' },
                                ]),
                                navService.initFooterMenus([
                                    { name: 'about', type: 'big-footer-about' },
                                    { name: 'accessibility', type: 'big-footer-accessibility' },
                                    { name: 'contact', type: 'big-footer-contact' },
                                    { name: 'legal', type: 'big-footer-legal' },
                                    { name: 'mobile', type: 'big-footer-mobile' },
                                    { name: 'social', type: 'big-footer-social' },
                                ]),
                            ]);
                            darkmodeService.handleDarkMode();
                        })
            )(inject(EnvironmentService), inject(FeatureFlagsService), inject(NavService), inject(DarkModeService));

            return initializerFn();
        }),
        { provide: STATIC_ENVIRONMENT, useValue: environment },
        provideHttpClient(withInterceptors([responseInterceptor]), withFetch(), withInterceptorsFromDi()),
        provideLoadingBarInterceptor(),
        provideLoadingBarRouter(),
        provideClientHydration(),
        provideTranslocoRoot(),

        provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withPreloading(PreloadAllModules)),

        importProvidersFrom(BrowserAnimationsModule),
        importProvidersFrom(JsonApiClientModule),
        importProvidersFrom(RestApiClientModule),
        importProvidersFrom(UserModule),
        importProvidersFrom(NgOverlayContainerModule),

        {
            provide: 'persistStorage',
            useValue: persistState(featureFlagsStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(darkmodeStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(userStore, {
                key: 'app',
                storage: localStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: 'persistStorage',
            useValue: persistState(uiStore, {
                key: 'app',
                storage: sessionStorageStrategy,
            }),
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LogSentryInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandlerService,
        },

        provideDppUserProfile(),

        // Nodes
        provideNodeBigAccordion(),
        provideNodeBigAccordionItem(),
        provideNodeBigArticle(),
        provideNodeBigDashboard(),
        provideNodeBigError(),
        provideNodeBigForm(),
        provideNodeBigLegal(),
        provideNodeBigMyBig(),
        provideNodeBigNews(),
        provideNodeBigTopBar(),
        provideNodeDPPArticle(),
        provideNodeDPPPrivateArea(),
        provideNodeDPPStartPage(),
        provideNodeQuickLink(),

        // Paragraphs
        provideAccordionParagraph(),
        provideBadgesParagraph(),
        provideButtonParagraph(),
        provideC2aBannerParagraph(),
        provideCardGroupParagraph(),
        provideCookiebotParagraph(),
        provideDPPGroupedLinkListsCollectorParagraph(),
        provideGapRemoverParagraph(),
        provideHeadingParagraph(),
        provideHealthInsuranceComparisonParagraph(),
        provideIframeParagraph(),
        provideImageCreditsParagraph(),
        provideImageParagraph(),
        provideInstructionParagraph(),
        provideKeyAccountManagerParagraph(),
        provideKeyAccountManagerSearchParagraph(),
        provideLinkListParagraph(),
        provideLinkParagraph(),
        provideListParagraph(),
        provideNewsTeaserParagraph(),
        provideQuickLinkListParagraph(),
        provideSalesPerformanceParagraph(),
        provideSalesPerformanceDashboardParagraph(),
        provideServiceToolParagraph(),
        provideTextButtonParagraph(),
        provideTextParagraph(),
        provideUserDataParagraph(),
        provideUserDetailsParagraph(),
        provideWebformParagraph(),

        // Service Tools
        provideAdvantageCalculatorServiceTool(),

        // Forms
        // Fields
        provideFormViewAddress(),
        provideFormViewAlert(),
        provideFormViewCheckbox(),
        provideFormViewCheckboxes(),
        provideFormViewContactFormSearch(),
        provideFormViewCurrencyInput(),
        provideFormViewDate(),
        provideFormViewDateRange(),
        provideFormViewDateList(),
        provideFormViewDateTime(),
        provideFormViewFileUpload(),
        provideFormViewHidden(),
        provideFormViewHin(),
        provideFormViewIFrame(),
        provideFormViewMarkup(),
        provideFormViewNavigationButton(),
        provideFormViewNumberField(),
        provideFormViewOneTimeToken(),
        provideFormViewPassword(),
        provideFormViewPasswordConfirm(),
        provideFormViewPrivacyPolicy(),
        provideFormViewQuantitySelect(),
        provideFormViewRadio(),
        provideFormViewRadios(),
        provideFormViewSearchableSelect(),
        provideFormViewSelect(),
        provideFormViewTextarea(),
        provideFormViewTextfield(),
        provideFormViewTime(),
        provideFormViewTimeRange(),

        // Preview
        provideFormViewPreview(),

        // Structures
        provideFormViewComposite(),
        provideFormViewContainer(),
        provideFormViewElementsHolder(),
        provideFormViewEmbeddedForm(),
        provideFormViewFieldGroup(),
        provideFormViewFieldSet(),
        provideFormViewForm(),
        provideFormViewFormRelation(),
        provideFormViewItem(),
        provideFormViewItemCollection(),
        provideFormViewLabel(),
        provideFormViewMultiple(),
        provideFormViewMultipleItem(),
        provideFormViewPage(),
        provideFormViewPdfDownload(),
        provideFormViewProgressBar(),
    ],
};
