@if (!hasErrors()) {
    <big-ui-footer>
        <div class="grid grid-cols-12 mb-2 gap-x-3 md:gap-y-10">
            @if (menus?.contact; as menu) {
                <div class="order-1 col-span-12 md:border-r-2 md:border-gray-300 lg:border-r-0 md:col-span-4 lg:col-span-3 md:block">
                    <big-ui-footer-menu
                        [menu]="menu"
                        [isContactMenu]="true" />
                </div>
            }
            @if (menus?.about; as menu) {
                <div class="order-2 col-span-12 md:border-r-2 md:border-gray-300 lg:border-r-0 md:col-span-4 lg:col-span-3 md:block">
                    <big-ui-footer-menu [menu]="menu" />
                </div>
            }
            @if (menus?.legal; as menu) {
                <div class="order-3 col-span-12 md:col-span-4 lg:col-span-3 md:block">
                    <big-ui-footer-menu [menu]="menu" />
                </div>
            }
            @if (menus?.accessibility; as menu) {
                <div class="order-3 col-span-12 md:col-span-4 lg:col-span-3 md:block md:border-r-2 md:border-gray-300 lg:border-r-0">
                    <big-ui-footer-menu [menu]="menu" />
                </div>
            }
            <div class="order-5 col-span-12 md:border-gray-300 md:flex md:justify-center md:pt-6 md:order-6 md:border-t-2">
                @if (menus?.social; as menu) {
                    <big-ui-footer-menu
                        [orientation]="'horizontal'"
                        [headerClass]="'md:sr-only'"
                        [listClass]="'lg:text-right mt-4 md:mt-0'"
                        [menu]="menu"
                        [iconMap]="iconMap" />
                }
            </div>
        </div>
        @if (menus?.mobile; as menu) {
            <div class="md:hidden">
                <ul class="flex">
                    @for (item of menu?.items; track $index) {
                        <li class="after:content-['|'] last:after:content-none after:mx-2">
                            <big-ui-link
                                [href]="item.uri"
                                [linkClass]="'no-underline'">
                                {{ item.title }}
                            </big-ui-link>
                        </li>
                    }
                </ul>
            </div>
        }
    </big-ui-footer>
}
