import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { RESOURCE_PATH_TOKEN, ResourceTypes } from '@big-direkt/rest-api-client';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { KeyAccountManagerSearchMapper } from './key-account-manager-search.mapper';

export const provideKeyAccountManagerSearchParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: RESOURCE_PATH_TOKEN, useValue: { [ResourceTypes.DPPContactPerson]: '/users/distributionpartner/contact-person' }, multi: true },
        { provide: MapperInjectorService.tokens[KeyAccountManagerSearchMapper.type], useClass: KeyAccountManagerSearchMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./key-account-manager-search.component'),
                type: ParagraphTypes.KeyAccountManagerSearch,
            },
            multi: true,
        },
    ]);
