import { makeEnvironmentProviders, type EnvironmentProviders } from '@angular/core';
import { MapperInjectorService } from '@big-direkt/json-api-client';
import { PARAGRAPHS_MAP } from '@big-direkt/paragraphs-holder';
import { ParagraphTypes } from '@big-direkt/utils/shared';
import { DownloadListMapper } from './mapper/download-list.mapper';
import { DPPGroupedLinkListsCollectorMapper } from './mapper/dpp-grouped-link-lists-collector.mapper';
import { GroupedLinkListsMapper } from './mapper/grouped-link-lists.mapper';

export const provideDPPGroupedLinkListsCollectorParagraph = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        { provide: MapperInjectorService.tokens[DPPGroupedLinkListsCollectorMapper.type], useClass: DPPGroupedLinkListsCollectorMapper },
        { provide: MapperInjectorService.tokens[GroupedLinkListsMapper.type], useClass: GroupedLinkListsMapper },
        { provide: MapperInjectorService.tokens[DownloadListMapper.type], useClass: DownloadListMapper },
        {
            provide: PARAGRAPHS_MAP,
            useValue: {
                import: async () => import('./components/dpp-grouped-link-lists-collector.component'),
                type: ParagraphTypes.GroupedLinkListsCollector,
            },
            multi: true,
        },
    ]);
